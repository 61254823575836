.p-inplace .p-inplace-display {
  display: inline;
  cursor: pointer;
  border: 0 none;
  font-weight: normal;
  font-style: italic;
  padding: 0.429em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  color: rgba(0,0,0,.5);
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background-color: #eaeaea;
  color: #333333;
}

.p-inplace .p-inplace-content {
  display: inline;
}
