/* ToDo: make it less hacky */
.bp3-table-quadrant {
  background: #fff !important;
}

.bp3-menu-item {
  outline: none;
}

.rxp-table-footer-button {
  position: absolute;
  top: 0;
  right: 2px;
  visibility: hidden;
}

.bp3-table-cell:hover .rxp-table-footer-button {
  visibility: visible;
}

.bp3-table-cell > :first-child {
  height: 100%;
  display: flex;
  align-items: center;
}

.bp3-table-cell .bp3-table-editable-name.bp3-editable-text::before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.bp3-table-cell .bp3-table-editable-text,
.bp3-table-row-headers .bp3-table-header {
  display: flex;
  align-items: center;
}

.bp3-table-cell.rxp-table-cell-select {
  padding: 0 5px;
}

.bp3-popover {
  font-family: Avenir Next, sans-serif;
}

.bp3-menu-item.bp3-intent-primary:hover,
.bp3-submenu
  .bp3-popover-target.bp3-popover-open
  > .bp3-intent-primary.bp3-menu-item,
.bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: #666;
  font-weight: 600;
}
