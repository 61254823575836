.rxp-close-icon {
  display: flex;
  justify-content: flex-end;
}

.rxp-label {
  font-size: 11px;
  line-height: 20px;
  color: #9999aa;
  font-family: avenir next, avenir, sans-serif;
}

.rxp-input {
  border: 1px solid #cdcee4;
  box-sizing: border-box;
  border-radius: 2px;
  height: 34px;
  padding: 0 0.5rem;
  font-family: avenir next, avenir, sans-serif;
  width: 100%;
}

.rxp-formula-input {
  font-family: Courier New, courier, monospace;
  line-height: 2.5rem;
  outline: none;
  height: auto;
  min-height: 34px;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.rxp-button {
  background: #1c00ff;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 36px;
  color: white;
}

.rxp-hint {
  font-size: 11px;
  line-height: 18px;
  color: #9999aa;
}

.rxp-field-error {
  font-size: 11px;
  line-height: 18px;
  color: #f44336;
}
